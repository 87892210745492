// @flow
import React from 'react'
import styled from 'styled-components'
import type { AreaTypes } from '../../types/enums/AreaTypes'
import type { VideoCardViewModel } from '../../types/VideoCardViewModel'
import VideoCard from '../../components/VideoCard/VideoCard'
import { useAtom } from 'jotai'
import { videoFilterAtom } from '../../App/Atoms'

type Props = {
  title?: string,
  subtitle?: string,
  area?: AreaTypes,
  videoItems?: Array<VideoCardViewModel>,
}

const StyledList = styled.ul`
  display: grid;
  gap: 40px;

  padding: 40px 0 0;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`
const StyledListWrapper = styled.div`
  padding: 0 30px;

  @media (min-width: 768px) {
    padding: 0;
  }
`

const StyledLi = styled.li`
  list-style: none;
`
const VideoList = ({ title, subtitle, area, videoItems }: Props) => {
  const [filter] = useAtom(videoFilterAtom)
  return (
    <>
      <StyledListWrapper>
        <StyledList>
          {videoItems
            ? filter?.searchParams && filter.searchParams.get('filter')
              ? videoItems
                  .filter(
                    video =>
                      video.subjectArea === filter.searchParams.get('filter'),
                  )
                  .map((video, index) => {
                    return (
                      <StyledLi key={index}>
                        <VideoCard
                          {...video}
                          isSingle={
                            videoItems.filter(
                              video =>
                                video.subjectArea ===
                                filter.searchParams.get('filter'),
                            ).length === 1
                          }
                          key={
                            video.title ? `${video.title}-${index}` : `${index}`
                          }
                        />
                      </StyledLi>
                    )
                  })
              : videoItems.map((video, index) => {
                  return (
                    <StyledLi key={index}>
                      <VideoCard
                        {...video}
                        BannerCard={false}
                        isSingle={videoItems?.length === 1}
                        key={
                          video.title ? `${video.title}-${index}` : `${index}`
                        }
                      />
                    </StyledLi>
                  )
                })
            : null}
        </StyledList>
      </StyledListWrapper>
    </>
  )
}

export default VideoList
