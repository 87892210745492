// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import type { VideoCardViewModel } from '../../types/VideoCardViewModel'
import type { AreaTypes } from '../../types/enums/AreaTypes'
import { atomWithLocation } from 'jotai-location'
import { useAtom } from 'jotai'
import { videoFilterAtom } from '../../App/Atoms'
import { getLineHeight, getFontSize } from '../../styles/style-helpers'
import { mediaQuery } from '../../styles/media'
import Button from '../../components/Button/Button'
import Ribbon from '../../components/Ribbon/Ribbon'
import Heading from '../../components/Heading/Heading'
import Paragraph from '../../components/Paragraph/Paragraph'
import { HyphenatedText } from '../../components/HyphenatedText/HyphenatedText'
import { useEffect, useState } from 'react'

type Props = {
  title?: string,
  subtitle?: string,
  area?: AreaTypes,
  videoItems?: Array<VideoCardViewModel>,
}

const InnerWrapper = styled.div`
  ${({ area }) =>
    area === 'wide' &&
    `
    padding-top: ${rem(48)};
    margin-bottom: ${rem(48)};
    margin-right: ${rem(64)};
  `}

  ${mediaQuery.md`
    ${({ area }) =>
      area === 'wide' &&
      `
      padding-top: ${rem(64)};
      margin-bottom: ${rem(64)};
      margin-right: ${rem(96)};
      max-width: 75%;
    `}
    line-height: ${getLineHeight('normal')};
  `};
`

const StyledHeading = styled(Heading)`
  ${mediaQuery.md`
    font-size: ${rem(44)};
  `}
`

const StyledParagraph = styled(Paragraph).attrs({
  weight: 500,
  size: 'large',
})`
  && {
    color: ${({ theme }) => theme.text};
    line-height: ${getLineHeight('normal')};
    font-size: ${getFontSize('large')};

    ${mediaQuery.md`
      max-width: 80%;
    `};
  }
`

const StyledVideoNav = styled.nav`
  border-top: 1px solid #edf1f2;
  @media (min-width: 768px) {
    padding: ${rem(40)} 0 0;
  }
`
const StyledInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 24px ${rem(32)} 44px;
  @media (min-width: 768px) {
    padding: 0 ${rem(54)} 0;
  }
`

const StyledButton = styled(Button)`
  font-size: 16px !important;

  background-color: ${({ chosen }) =>
    chosen ? '#c4e1e4' : '#c4e1e433 '} !important;

  &:hover,
  &:active {
    color: #003851 !important;

    background-color: #c4e1e4 !important;
  }
`

const locationAtom = atomWithLocation()

const IntroWithNavigation = ({ title, subtitle, area, videoItems }: Props) => {
  const [loc, setLoc] = useAtom(locationAtom)
  const pathName = loc?.pathname
  const [filter, setFilter] = useAtom(videoFilterAtom)
  const [number, setNumber] = useState(42)

  useEffect(() => {
    setFilter(loc)
  }, [loc])

  useEffect(() => {
    function handleResize() {
      if (window.matchMedia('(max-width: 768px)').matches) {
        setNumber(43)
      } else {
        setNumber(96)
      }
    }

    // Call the handleResize function to set the initial value
    handleResize()

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const subjectAreas = videoItems
    ? [
        ...new Set(
          videoItems.map(video => {
            return video.subjectArea
          }),
        ),
      ]
    : null
  return (
    <>
      <Ribbon marginBottom={false} area={area}>
        <div>
          <InnerWrapper area={area}>
            {title && (
              <StyledHeading tag="h1" level={1} responsiveSize>
                <HyphenatedText text={title} minWordLength={4} />
              </StyledHeading>
            )}
            {subtitle && <StyledParagraph>{subtitle}</StyledParagraph>}
          </InnerWrapper>
        </div>
      </Ribbon>
      <Ribbon area={area} roundedBottom paddingTop={number} noGrid>
        <StyledVideoNav aria-label="video navigation">
          <StyledInnerWrapper>
            <StyledButton
              type="filter"
              label="Alle Fagområder"
              chosen={filter?.searchParams?.size === 0}
              onClick={() => {
                setLoc(prev => ({
                  ...prev,
                  pathname: pathName,
                  searchParams: new URLSearchParams(undefined),
                }))
              }}
            />
            {subjectAreas
              ? subjectAreas.map(subject => {
                  return subject ? (
                    <StyledButton
                      type="filter"
                      chosen={
                        filter?.searchParams &&
                        filter.searchParams.get('filter') === subject
                      }
                      onClick={() => {
                        setLoc(prev => ({
                          ...prev,
                          pathname: pathName,
                          searchParams: new URLSearchParams([
                            ['filter', `${subject}`],
                          ]),
                        }))
                      }}
                      label={subject}
                      key={subject}
                    />
                  ) : null
                })
              : null}
          </StyledInnerWrapper>
        </StyledVideoNav>
      </Ribbon>
    </>
  )
}

export default IntroWithNavigation
