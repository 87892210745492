// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { durations } from '../../styles/animations'
import { color, effects } from '../../styles/theme'
import PlayIcon from '../../icons/PlayIcon' // Import the 'PlayIcon' component
import { getFontSize, getLineHeight } from '../../styles/style-helpers' // Import the necessary function
import consecutive from 'consecutive'
import Heading from '../../components/Heading/Heading'
import { HyphenatedText } from '../HyphenatedText/HyphenatedText' // Import the 'HyphenatedText' component
import type { VideoCardViewModel } from '../../types/VideoCardViewModel'
import Image from '../../components/Image/Image'

type Props = VideoCardViewModel & { onHandleOpen?: () => void }

type State = {}

const keyGen = consecutive()
class VideoCardClosed extends React.Component<Props, State> {
  static displayName = 'VideoCardClosed'
  static defaultProps = {}

  render() {
    const {
      video,
      isSingle,
      onHandleOpen,
      subjectArea,
      title,
      description,
      BannerCard,
    } = this.props

    const hyphenatedTitle = <HyphenatedText text={title} minWordLength={4} />

    const Card = styled.button`
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: calc(100% - ${rem(20)});
      margin-bottom: ${rem(28)};
      overflow: hidden;
      cursor: pointer;
      border-radius: 8px;
      transition: transform ${durations.fast} ease-in-out;
      border:none;
      text-align:start;
      padding-inline:0;
      &:hover {
        transform: translateY(-3px);
      }

      &:hover img {
        transform: scale(1.1);
      }

      &:hover svg {
        transition: ${effects().linkHoverTransition};
        color: ${color.accent};
      }

      @media (min-width: 768px) {
      display ${({ isSingle }) => (isSingle ? 'grid' : 'flex')};
      grid-template-columns: 1fr 1fr;
      margin-bottom: 40px;
      }
`

    const StyledVideoCardImage = styled.div`
      display: grid;
      align-items: center;
      grid-template-columns: 1fr;
      justify-items: center;
      cursor: pointer;
      width: 100%;
      height: 100%;
    `
    const StyledImage = styled(Image)`
      position: relative;

      padding-top: 75%;
      object-fit: contain;
      height: 100%;
      grid-column-start: 1;
      grid-row-start: 1;
    `

    const StyledPlayButton = styled.div`
      opacity: 1;
      z-index: 20;
      grid-column-start: 1;
      grid-row-start: 1;

      /* sets svg size */
      font-size: 64px;
    `

    const StyledWrapper = styled.div`
      display: flex;
      flex-direction: column;
      height: 100%;

      background-color: ${({ BannerCard }) =>
        BannerCard ? `#EDF1F2` : `white`};

      padding: ${({ transparent }) =>
        transparent ? `0 0 ${rem(38)}` : `${rem(64)} ${rem(40)} ${rem(40)}`};
      ${({ image }) => (image ? 'border-top: 0;' : '')};

      ${({ image }) =>
        !!image &&
        `
          padding-top: ${rem(48)};
        `}

      ${({ theme }) =>
        (theme.name === 'kids' || theme.name === 'lgbt') &&
        `
            padding:  ${rem(24)};
          `}

> :last-child {
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        padding: 48px;
      }
    `
    const StyledSubtitleWrapper = styled.div`
      margin-bottom: ${rem(16)};

      /* these 3 lines enforce ellipsis effect when text is too wide */
      display: table;
      table-layout: fixed;
      width: 100%;
    `

    const StyledSubtitle = styled.span`
      font-size: ${getFontSize('tiny')};
      font-weight: 600;
      letter-spacing: 0cap.6;
      text-transform: uppercase;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #003851;
      line-height: 16.8px;
    `

    const StyledParagraph = styled.div`
      margin-bottom: ${rem(32)};
      color: ${props => props.theme.text};
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4; /* Number of lines you want to display */
      overflow: hidden;

      && {
        line-height: ${getLineHeight('normal')};
      }
    `

    return (
      <Card
        onClick={onHandleOpen && onHandleOpen}
        link={video?.src}
        isSingle={isSingle}
      >
        {video && (
          <StyledVideoCardImage>
            <StyledImage
              cover
              fillContainer
              zoomOnHover
              {...video?.poster}
            ></StyledImage>

            <StyledPlayButton>
              <PlayIcon />
            </StyledPlayButton>
          </StyledVideoCardImage>
        )}

        <StyledWrapper BannerCard={BannerCard}>
          {subjectArea && (
            <StyledSubtitleWrapper>
              <StyledSubtitle
                key={`ListCardPreTitle-${keyGen()}`}
                title={subjectArea}
              >
                {subjectArea}
              </StyledSubtitle>
            </StyledSubtitleWrapper>
          )}

          {title && (
            <Heading
              tag="h2"
              level={2}
              responsiveSize
              style={{
                color: '#003851',
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '28px',
                marginBottom: '0.5rem',
              }}
            >
              {hyphenatedTitle}
            </Heading>
          )}

          {description && (
            <StyledParagraph
              dangerouslySetInnerHTML={{ __html: description }}
            ></StyledParagraph>
          )}
        </StyledWrapper>
      </Card>
    )
  }
}

export default VideoCardClosed
