// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import type { AreaTypes } from '../../types/enums/AreaTypes'

import { getLineHeight, getFontSize } from '../../styles/style-helpers'
import { mediaQuery } from '../../styles/media'

import Ribbon from '../../components/Ribbon/Ribbon'
import Heading from '../../components/Heading/Heading'
import Paragraph from '../../components/Paragraph/Paragraph'
import { HyphenatedText } from '../../components/HyphenatedText/HyphenatedText'

export type Props = {
  title?: string,
  subtitle?: string,
  area?: AreaTypes,
}

const InnerWrapper = styled.div`
  margin: 0;

  ${({ area }) =>
    area === 'wide' &&
    `
    padding-top: ${rem(48)};
    margin-bottom: ${rem(48)};
    margin-right: ${rem(64)};
  `}

  ${mediaQuery.md`
    ${({ area }) =>
      area === 'wide' &&
      `
      padding-top: ${rem(64)};
      margin-bottom: ${rem(64)};
      margin-right: ${rem(96)};
      max-width: 75%;
    `}

    line-height: ${getLineHeight('normal')};
  `};
`

const StyledHeading = styled(Heading)`
  ${mediaQuery.md`
    font-size: ${rem(44)};
  `}
`

const StyledParagraph = styled(Paragraph).attrs({
  weight: 500,
  size: 'large',
})`
  && {
    color: ${({ theme }) => theme.text};
    line-height: ${getLineHeight('normal')};
    font-size: ${getFontSize('large')};

    ${mediaQuery.md`
      max-width: 80%;
    `};
  }
`

type State = {}

class Intro extends React.Component<Props, State> {
  static displayName = 'Intro'
  static defaultProps = {}

  render() {
    const { title, subtitle, area } = this.props

    return (
      <Ribbon>
        <div>
          <InnerWrapper area={area}>
            {title && (
              <StyledHeading tag="h1" level={1} responsiveSize>
                <HyphenatedText text={title} minWordLength={4} />
              </StyledHeading>
            )}
            {subtitle && <StyledParagraph>{subtitle}</StyledParagraph>}
          </InnerWrapper>
        </div>
      </Ribbon>
    )
  }
}

export default Intro
